
import React from 'react';
import { Link } from "react-router-dom";

import Logo from '../components/Logo';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import { PATH_AUTH } from '../routes/paths';

function Pricing() {

    return (
        <>
            <header className="nk-header bg-darker is-dark has-mask">
                <div className="nk-mask bg-pattern-dot-white-sm bg-blend-bottom"></div>
                <div
                    className="nk-header-main nk-menu-main is-transparent will-shrink on-dark ignore-mask"
                >
                    <div className="container">
                        <div className="nk-header-wrap">
                            <Logo />
                            <Menu />
                        </div>
                    </div>
                </div>
                <div className="nk-page-head">
                    <div className="container">
                        <div className="row justify-content-center text-center">
                            <div className="col-lg-8 col-xl-7 col-xxl-6">
                                <div
                                    className="badge text-bg-primary text-uppercase text-tracking-1 rounded-pill px-3 py-2 mb-3"
                                >
                                    Pricing
                                </div>
                                <h2 className="title h1">
                                    Plans that <span className="text-primary">start free</span> and
                                    fits with your needs
                                </h2>
                                <p className="lead">
                                    With our simple plans, supercharge your content writing to
                                    helps your business. Let’s make great content together.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <main className="nk-pages">
                <section className="section section-bottom-0 bg-light rounded-top-6">
                    <div className="container">
                        <div className="section-content">
                            <div className="pricing-toggle-wrap mb-5 pb-2">
                                <div className="h5 mb-0 pricing-toggle-text monthly">Monthly</div>
                                <div className="mx-3">
                                    <button
                                        className="pricing-toggle"
                                        data-parent="pricing-toggle-wrap"
                                        data-target="pricing-toggle-content"
                                    >
                                        <span className="pricing-toggle-ball"></span>
                                    </button>
                                </div>
                                <div
                                    className="h5 mb-0 pricing-toggle-text yearly position-relative"
                                >
                                    Yearly
                                    <span
                                        className="badge text-bg-success-soft-outline fw-normal text-uppercase smaller rounded-pill position-absolute ms-n5 mb-2 mb-sm-0 ms-sm-3 translate-middle-sm-y start-100 bottom-100 bottom-sm-auto top-sm-50"
                                    >Save 12%</span
                                    >
                                </div>
                            </div>
                            <div className="row g-gs">
                                <div className="col-xxl-4 col-xl-4">
                                    <div
                                        className="pricing h-100 pricing-featured bg-gradient-primary"
                                    >
                                        <div
                                            className="pricing-body h-100 p-5 pt-3 p-md-0 pt-md-0 p-xl-5 pt-xl-3 d-md-flex d-xl-block"
                                        >
                                            <div className="text-center p-md-5 p-xl-0 w-md-50 w-xl-100">
                                                <div
                                                    className="badge bg-gradient-primary bg-opacity-20 gradient-angle-90 mb-4 px-3 py-2 rounded-pill small text-primary text-tracking-1"
                                                >
                                                    <div className="p-1">Most Popurlar</div>
                                                </div>
                                                <h3 className="mb-3">Pro</h3>
                                                <div className="pricing-price-wrap pricing-toggle-content">
                                                    <div className="pricing-price monthly">
                                                        <h3 className="display-5 mb-3">
                                                            $19
                                                            <span className="caption-text text-muted">
                                                                / month</span
                                                            >
                                                        </h3>
                                                    </div>
                                                    <div className="pricing-price yearly">
                                                        <h3 className="display-5 mb-3">
                                                            $190
                                                            <span className="caption-text text-muted">
                                                                / yearly</span
                                                            >
                                                        </h3>
                                                    </div>
                                                </div>
                                                <p className="fw-bold text-primary">
                                                    For content marketers, bloggers, freelancers &amp;
                                                    startups
                                                </p>
                                                <div className="bg-light px-4 py-2 mb-4 smaller rounded-1">
                                                    Try out all features to determine what works best for
                                                    you
                                                </div>
                                                <div className="px-4 pricing-toggle-content">
                                                    <div className="pricing-toggle-option monthly">
                                                        <Link
                                                            to={PATH_AUTH.signUp}
                                                            className="btn btn-primary btn-block rounded-pill"
                                                        >Get Started
                                                        </Link>
                                                    </div>
                                                    <div className="pricing-toggle-option yearly">
                                                        <Link
                                                            to={PATH_AUTH.signUp}
                                                            className="btn btn-primary btn-block rounded-pill"
                                                        >
                                                            Get Committed</Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="d-none d-md-block d-xl-none border-start h-100"
                                            ></div>
                                            <div className="pt-4 p-md-5 p-xl-0 pt-xl-4 w-md-50 w-xl-100">
                                                <ul className="list gy-3">
                                                    <li>
                                                        <em
                                                            className="icon fs-4 ni ni-check-circle-fill text-info"
                                                        ></em>
                                                        <span><strong>50</strong> Articles per month</span>
                                                    </li>
                                                    <li>
                                                        <em
                                                            className="icon fs-4 ni ni-check-circle-fill text-info"
                                                        ></em>
                                                        <span><strong>10</strong> Templates</span>
                                                    </li>
                                                    <li>
                                                        <em
                                                            className="icon fs-4 ni ni-check-circle-fill text-info"
                                                        ></em>
                                                        <span><strong>15</strong> Languages</span>
                                                    </li>
                                                    <li>
                                                        <em
                                                            className="icon fs-4 ni ni-check-circle-fill text-info"
                                                        ></em>
                                                        <span>AI Article Writer</span>
                                                    </li>
                                                    <li>
                                                        <em
                                                            className="icon fs-4 ni ni-check-circle-fill text-info"
                                                        ></em>
                                                        <span>Advance Editor Tool</span>
                                                    </li>
                                                    <li>
                                                        <em
                                                            className="icon fs-4 ni ni-check-circle-fill text-info"
                                                        ></em>
                                                        <span>Regular support</span>
                                                    </li>
                                                    <li>
                                                        <em
                                                            className="icon fs-4 ni ni-check-circle-fill text-info"
                                                        ></em>
                                                        <span>Priority support</span>
                                                    </li>
                                                    <li>
                                                        <em
                                                            className="icon fs-4 ni ni-cross-circle-fill text-info"
                                                        ></em>
                                                        <span>Premium support</span>
                                                    </li>
                                                    <li>
                                                        <em
                                                            className="icon fs-4 ni ni-check-circle-fill text-info"
                                                        ></em>
                                                        <span>Newest Features</span>
                                                    </li>
                                                    <li>
                                                        <em
                                                            className="icon fs-4 ni ni-check-circle-fill text-info"
                                                        ></em>
                                                        <span>Cancel any time</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xxl-4 col-xl-4 col-md-6 order-xl-first">
                                    <div className="h-100 pt-xl-6">
                                        <div className="pricing h-100">
                                            <div className="pricing-body h-100 p-5">
                                                <div className="text-center">
                                                    <h3 className="mb-3">Free</h3>
                                                    <h3 className="display-5 mb-3">
                                                        $0
                                                        <span className="caption-text text-muted">
                                                            / month</span
                                                        >
                                                    </h3>
                                                    <p className="fw-bold">
                                                        Access to AI writer features to help you get a taste
                                                        of AI writing.
                                                    </p>
                                                    <div
                                                        className="bg-light px-4 py-2 mb-4 smaller rounded-1"
                                                    >
                                                        Try out all features to determine what works best
                                                        for you
                                                    </div>
                                                    <div className="px-4">
                                                        <Link
                                                            to={PATH_AUTH.signUp}
                                                            className="btn btn-outline-primary btn-block rounded-pill"
                                                        >
                                                            Start Writing for Free
                                                        </Link>
                                                    </div>
                                                </div>
                                                <ul className="list gy-3 mt-2">
                                                    <li>
                                                        <em
                                                            className="icon fs-4 ni ni-check-circle-fill text-info"
                                                        ></em>
                                                        <span><strong>10</strong> Articles per month</span>
                                                    </li>
                                                    <li>
                                                        <em
                                                            className="icon fs-4 ni ni-check-circle-fill text-info"
                                                        ></em>
                                                        <span><strong>1</strong> Templates</span>
                                                    </li>
                                                    <li>
                                                        <em
                                                            className="icon fs-4 ni ni-check-circle-fill text-info"
                                                        ></em>
                                                        <span><strong>1</strong> Languages</span>
                                                    </li>
                                                    <li>
                                                        <em
                                                            className="icon fs-4 ni ni-check-circle-fill text-info"
                                                        ></em>
                                                        <span>AI Article Writer</span>
                                                    </li>
                                                    <li>
                                                        <em
                                                            className="icon fs-4 ni ni-check-circle-fill text-info"
                                                        ></em>
                                                        <span>Advance Editor Tool</span>
                                                    </li>
                                                    <li>
                                                        <em
                                                            className="icon fs-4 ni ni-check-circle-fill text-info"
                                                        ></em>
                                                        <span>Regular support</span>
                                                    </li>
                                                    <li>
                                                        <em
                                                            className="icon fs-4 ni ni-cross-circle-fill text-info"
                                                        ></em>
                                                        <span>Priority support</span>
                                                    </li>
                                                    <li>
                                                        <em
                                                            className="icon fs-4 ni ni-cross-circle-fill text-info"
                                                        ></em>
                                                        <span>Premium support</span>
                                                    </li>
                                                    <li>
                                                        <em
                                                            className="icon fs-4 ni ni-check-circle-fill text-info"
                                                        ></em>
                                                        <span>Newest Features</span>
                                                    </li>
                                                    <li>
                                                        <em
                                                            className="icon fs-4 ni ni-check-circle-fill text-info"
                                                        ></em>
                                                        <span>Cancel any time</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xxl-4 col-xl-4 col-md-6">
                                    <div className="h-100 pt-xl-6">
                                        <div className="pricing h-100">
                                            <div className="pricing-body h-100 p-5">
                                                <div className="text-center">
                                                    <h3 className="mb-3">Custom</h3>
                                                    <div
                                                        className="media media-middle media-2xl bg-light rounded-pill mb-4 mt-3"
                                                    >
                                                        <em className="icon ni ni-building"></em>
                                                    </div>
                                                    <p className="fw-bold">
                                                        Design your custom package for teams and business
                                                        needs
                                                    </p>
                                                    <div
                                                        className="bg-light px-4 py-2 mb-4 smaller rounded-1"
                                                    >
                                                        Take your business to the another level with custom
                                                        package and support.
                                                    </div>
                                                    <div className="px-4">
                                                        <Link
                                                            to={PATH_AUTH.signUp}
                                                            className="btn btn-outline-primary btn-block rounded-pill"
                                                        >Request for Demo</Link>
                                                    </div>
                                                </div>
                                                <ul className="list gy-3 mt-2">
                                                    <li>
                                                        <em
                                                            className="icon fs-4 ni ni-check-circle-fill text-info"
                                                        ></em>
                                                        <span><strong>Custom pricing</strong></span>
                                                    </li>
                                                    <li>
                                                        <em
                                                            className="icon fs-4 ni ni-check-circle-fill text-info"
                                                        ></em>
                                                        <span>Custom number of users</span>
                                                    </li>
                                                    <li>
                                                        <em
                                                            className="icon fs-4 ni ni-check-circle-fill text-info"
                                                        ></em>
                                                        <span>Custom number of articles</span>
                                                    </li>
                                                    <li>
                                                        <em
                                                            className="icon fs-4 ni ni-check-circle-fill text-info"
                                                        ></em>
                                                        <span>Manage team member</span>
                                                    </li>
                                                    <li>
                                                        <em
                                                            className="icon fs-4 ni ni-check-circle-fill text-info"
                                                        ></em>
                                                        <span>Premium support</span>
                                                    </li>
                                                    <li>
                                                        <em
                                                            className="icon fs-4 ni ni-check-circle-fill text-info"
                                                        ></em>
                                                        <span>Newest Features</span>
                                                    </li>
                                                    <li>
                                                        <em
                                                            className="icon fs-4 ni ni-check-circle-fill text-info"
                                                        ></em>
                                                        <span>Advance Editor Tool</span>
                                                    </li>
                                                    <li>
                                                        <em
                                                            className="icon fs-4 ni ni-check-circle-fill text-info"
                                                        ></em>
                                                        <span>AI Article Writer</span>
                                                    </li>
                                                    <li>
                                                        <em
                                                            className="icon fs-4 ni ni-check-circle-fill text-info"
                                                        ></em>
                                                        <span>Cancel any time</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section bg-light">
                    <div className="container">
                        <div className="section-head">
                            <div className="row justify-content-center text-center">
                                <div className="col-xl-8">
                                    <h2 className="title">Frequently Asked Questions</h2>
                                    <p className="lead">
                                        If you have any questions not answered in the FAQ, please do
                                        not hesitate to contac us.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="section-content">
                            <div className="row g-gs justify-content-center">
                                <div className="col-xl-9 col-xxl-8">
                                    <div
                                        className="accordion accordion-separated accordion-plus-minus"
                                        id="faq-1"
                                    >
                                        <div className="accordion-item">
                                            <h2 className="accordion-header">
                                                <button
                                                    className="accordion-button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#faq-1-1"
                                                >
                                                    How to start using a Stralight
                                                </button>
                                            </h2>
                                            <div
                                                id="faq-1-1"
                                                className="accordion-collapse collapse show"
                                                data-bs-parent="#faq-1"
                                            >
                                                <div className="accordion-body">
                                                    Download the Stralight app, create an account, explore subjects, take quizzes, and learn from the explanations.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header">
                                                <button
                                                    className="accordion-button collapsed"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#faq-1-2"
                                                >
                                                    Does Stralight to write affiliate marketing content?
                                                </button>
                                            </h2>
                                            <div
                                                id="faq-1-2"
                                                className="accordion-collapse collapse"
                                                data-bs-parent="#faq-1"
                                            >
                                                <div className="accordion-body">
                                                    Stralight can definitely assist you with affiliate marketing content by providing ideas, outlines, and product information to craft compelling articles and reviews.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header">
                                                <button
                                                    className="accordion-button collapsed"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#faq-1-3"
                                                >
                                                    Is the generated content original?
                                                </button>
                                            </h2>
                                            <div
                                                id="faq-1-3"
                                                className="accordion-collapse collapse"
                                                data-bs-parent="#faq-1"
                                            >
                                                <div className="accordion-body">
                                                    Yes, you can write long articel for your blog posts,
                                                    product descriptions or any long article with Stralight.
                                                    We're always updating our template and tools, so let
                                                    us know what are expecting!
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header">
                                                <button
                                                    className="accordion-button collapsed"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#faq-1-4"
                                                >
                                                    Do you have wordpress plugin?
                                                </button>
                                            </h2>
                                            <div
                                                id="faq-1-4"
                                                className="accordion-collapse collapse"
                                                data-bs-parent="#faq-1"
                                            >
                                                <div className="accordion-body">
                                                    Yes, Stralight actually offers a WordPress plugin alongside a Chrome extension to streamline your affiliate content creation process.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
}

export default Pricing;
