import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// Home
import Home from './../pages/Home';
import Features from '../pages/Features';
import Pricing from '../pages/Pricing';
import Blog from '../pages/Blog';
import Contact from '../pages/Contact';
import TermsCondition from '../pages/TermsCondition';

import { PATH_PAGE } from './paths';
import Page404 from '../pages/404';

function Routers() {
    return (
        <Router>
            <Routes>
                {/* Home */}
                <Route path="/" element={<Home />} />
                <Route path={PATH_PAGE.features} element={<Features />} />
                <Route path={PATH_PAGE.pricing} element={<Pricing />} />
                <Route path={PATH_PAGE.blog} element={<Blog />} />
                <Route path={PATH_PAGE.contact} element={<Contact />} />
                <Route path={PATH_PAGE.documentation} element={<Features />} />
                <Route path={PATH_PAGE.termsCondition} element={<TermsCondition />} />

                <Route path='/*' element={<Page404 />} />
            </Routes>
        </Router>
    );
}

export default Routers;
