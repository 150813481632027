
import React from 'react';
import { Link } from "react-router-dom";

import Logo from '../components/Logo';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import EndWriterBlockToday from '../components/EndWriterBlockToday';

function Blog() {

    return (
        <>
            <header className="nk-header">
                <div
                    className="nk-header-main nk-menu-main will-shrink is-transparent ignore-mask"
                >
                    <div className="container">
                        <div className="nk-header-wrap">
                            <Logo />
                            <Menu />
                        </div>
                    </div>
                </div>
            </header>
            <main className="nk-pages">
                <section className="section section-bottom-0 has-shape has-mask">
                    <div
                        className="nk-shape bg-shape-blur-m mt-8 start-50 top-0 translate-middle-x"
                    ></div>
                    <div
                        className="nk-shape bg-shape-blur-m mt-8 start-50 top-50 translate-middle-x"
                    ></div>
                    <div
                        className="nk-mask bg-pattern-dot bg-blend-around mt-n5 mb-10p mh-50vh"
                    ></div>
                    <div className="container">
                        <div className="section-head">
                            <div className="row justify-content-center text-center">
                                <div className="col-lg-9 col-xl-7 col-xxl-6">
                                    <h6 className="overline-title text-primary">Blog</h6>
                                    <h1 className="title">
                                        News, tips and resources about Stralight AI
                                    </h1>
                                    <label id="blog-search" className="d-flex align-items-center border rounded-3 py-2 px-4 mt-5 mt-lg-7 bg-white">
                                        <em className="icon me-3 fs-3 ni ni-search"></em>
                                        <input
                                            type="text"
                                            name="blog-search"
                                            className="form-control form-control-lg form-control-plaintext"
                                            placeholder="Search for articles"
                                            required
                                        />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="section-content">
                            <div className="row g-gs">
                                <div className="col-md-6 col-xl-4">
                                    <div className="card border-0 shadow-tiny rounded-4">
                                        <div className="card-body p-4">
                                            <Link to="#" className="d-block">
                                                <img
                                                    className="rounded-4 w-100"
                                                    src="/assets/images/b.jpg"
                                                    alt="" />
                                            </Link>
                                            <Link
                                                to="#"
                                                className="badge px-3 py-2 mt-4 mb-3 text-bg-primary-soft fw-normal rounded-pill"
                                            >
                                                Artificial Intelligence
                                            </Link>
                                            <h3>
                                                <Link
                                                    href="#"
                                                    className="link-dark line-clamp-2"
                                                >
                                                    What Is An AI Writing Assistant, And How Is It Beneficial?
                                                </Link>
                                            </h3>
                                            <div className="d-flex pt-4">
                                                <div className="media-info ms-3">
                                                    {/* <h6 className="mb-1">Frances Burns</h6> */}
                                                    <ul className="list list-row gx-1">
                                                        <li><div className="sub-text">Feb 9, 2024</div></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-4">
                                    <div className="card border-0 shadow-tiny rounded-4">
                                        <div className="card-body p-4">
                                            <Link to="#" className="d-block">
                                                <img
                                                    className="rounded-4 w-100"
                                                    src="/assets/images/e.jpg"
                                                    alt="" />
                                            </Link>
                                            <Link
                                                href="#"
                                                className="badge px-3 py-2 mt-4 mb-3 text-bg-primary-soft fw-normal rounded-pill"
                                            >Stralight</Link>
                                            <h3>
                                                <Link
                                                    to="#"
                                                    className="link-dark line-clamp-2"
                                                >How AI Helps with Online Content Creation
                                                </Link>
                                            </h3>
                                            <div className="d-flex pt-4">
                                                <div className="media-info ms-3">
                                                    <ul className="list list-row gx-1">
                                                        <li><div className="sub-text">Feb 7, 2024</div></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-4">
                                    <div className="card border-0 shadow-tiny rounded-4">
                                        <div className="card-body p-4">
                                            <Link to="#" className="d-block" >
                                                <img
                                                    className="rounded-4 w-100"
                                                    src="/assets/images/d.jpg"

                                                    alt="" />
                                            </Link>
                                            <Link
                                                href="#"
                                                className="badge px-3 py-2 mt-4 mb-3 text-bg-primary-soft fw-normal rounded-pill"
                                            >
                                                Stralight
                                            </Link>
                                            <h3>
                                                <Link
                                                    to="#"
                                                    className="link-dark line-clamp-2"
                                                >5 Reasons Why Stralight Is the Best AI for Affiliate Marketing
                                                </Link>
                                            </h3>
                                            <div className="d-flex pt-4">
                                                <div className="media-info ms-3">
                                                    <ul className="list list-row gx-1">
                                                        <li><div className="sub-text">Feb 7, 2024</div></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="section-actions text-center">
                            <ul className="btn-list btn-list-inline g-gs">
                                <li>
                                    <Link href="#" className="btn btn-primary btn-soft btn-lg"
                                    ><em className="icon ni ni-arrow-long-left"></em
                                    ><span>Previous</span></Link>
                                </li>
                                <li>
                                    <Link href="#" className="btn btn-primary btn-lg"
                                    ><span>Next</span
                                    ><em className="icon ni ni-arrow-long-right"></em
                                    ></Link>
                                </li>
                            </ul>
                        </div> */}
                    </div>
                </section>
                <EndWriterBlockToday />
            </main>
            <Footer />
        </>
    );
}

export default Blog;
