
import React from 'react';
import { Link } from "react-router-dom";

import Logo from '../components/Logo';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import { PATH_AUTH } from '../routes/paths';

function Home() {

    return (
        <>
            <header className="nk-header has-shape">
                <div className="nk-shape bg-shape-blur-q mt-n20p ms-10p start-50 translate-middle-x"></div>
                <div className="nk-shape bg-shape-blur-r mt-n10p ms-n10p start-50 translate-middle-x"></div>
                <div className="nk-shape bg-shape-border-a mt-n10 ms-40p start-50 top-100 translate-middle-x"></div>
                <div className="nk-shape bg-shape-border-b mt-n9 ms-30p start-50 translate-middle-x"></div>
                <div className="nk-shape bg-shape-border-c mt-12 ms-n40p start-50 translate-middle-x"></div>
                <div className="nk-header-main nk-menu-main will-shrink is-transparent ignore-mask">
                    <div className="container">
                        <div className="nk-header-wrap">
                            <Logo />

                            <Menu />
                        </div>
                    </div>
                </div>
                <div className="nk-hero pt-4 pt-lg-6 pt-xl-12 pb-xl-4">
                    <div className="container">
                        <div
                            className="row g-gs align-items-center justify-content-center justify-content-xl-between flex-xl-row-reverse text-center text-xl-start"
                        >
                            <div className="col-xl-6 col-xxl-5 col-lg-7 col-md-10">
                                <div className="nk-hero-gfx me-xxl-n7">
                                    <div className="p-1 rounded-3 bg-gradient-primary">
                                        <img
                                            className="w-100 rounded-3"
                                            src="./assets/images/g.jpg"
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-7 col-xl-6 col-lg-11">
                                <div className="nk-hero-content">
                                    <h5 className="text-uppercase fw-normal mb-3">
                                        Welcome to Stralight.com
                                    </h5>
                                    <h1 className="title mb-3 mb-lg-4">
                                        Create amazon
                                        <span className="text-gradient-primary">&nbsp; affiliate content</span>
                                        &nbsp;posts faster with AI.
                                    </h1>
                                    <p className="lead">
                                        Elegantly structured content with references in just a few
                                        clicks.
                                    </p>
                                    <ul className="btn-list btn-list-inline py-3 gy-3">
                                        <li>
                                            <Link to={PATH_AUTH.signUp} className="btn btn-primary btn-lg">
                                                <span>Sign up with email</span>
                                                <em className="icon ni ni-arrow-long-right"></em>
                                            </Link>
                                        </li>
                                    </ul>
                                    <p className="sub-text mt-2">
                                        <strong>*100% free </strong> to get started. No credit card
                                        required.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <main className="nk-pages">
                <section class="section section-lg">
                    <div class="container">
                        <div class="section-head">
                            <div class="row justify-content-center text-center">
                                <div class="col-lg-9 col-xl-8 col-xxl-6">
                                    <h6 class="overline-title text-primary">Stralight.com Overview</h6>
                                    <h2 class="title">What amazing content will you create with AI?</h2>
                                </div>
                            </div>
                        </div>
                        <div class="section-content">
                            <div class="overflow-hidden">
                                <ul class="nav nav-tabs nav-tabs-stretch mb-5" role="tablist">
                                    <li class="w-100 w-sm-50 w-lg-auto"><button class="nav-link w-100 w-lg-auto active"
                                        data-bs-toggle="tab" data-bs-target="#social-media-adds" aria-selected="true" role="tab"><em
                                            class="icon ni ni-flag"></em><span>Social Media &amp; Ads</span></button></li>
                                    <li class="w-100 w-sm-50 w-lg-auto"><button class="nav-link w-100 w-lg-auto" data-bs-toggle="tab"
                                        data-bs-target="#website-copy-seo" aria-selected="false" role="tab" tabindex="-1"><em
                                            class="icon ni ni-globe"></em><span>Website Copy &amp; SEO</span></button></li>
                                    <li class="w-100 w-sm-50 w-lg-auto"><button class="nav-link w-100 w-lg-auto" data-bs-toggle="tab"
                                        data-bs-target="#blog-section-writing" aria-selected="false" role="tab" tabindex="-1"><em
                                            class="icon ni ni-edit"></em><span>Blog Section Writing</span></button></li>
                                    <li class="w-100 w-sm-50 w-lg-auto"><button class="nav-link w-100 w-lg-auto" data-bs-toggle="tab"
                                        data-bs-target="#ecommerce-copy" aria-selected="false" role="tab" tabindex="-1"><em
                                            class="icon ni ni-briefcase"></em><span>eCommerce Copy</span></button></li>
                                    <li class="w-100 w-sm-50 w-lg-auto"><button class="nav-link w-100 w-lg-auto" data-bs-toggle="tab"
                                        data-bs-target="#magic-command" aria-selected="false" role="tab" tabindex="-1"><em
                                            class="icon ni ni-chat-msg"></em><span>Magic Command</span></button></li>
                                </ul>
                            </div>
                            <div class="tab-content p-5 card border-0 rounded-4 shadow-sm">
                                <div class="tab-pane p-lg-3 fade active show" id="social-media-adds" tabindex="0" role="tabpanel">
                                    <div class="row g-gs flex-xl-row-reverse">
                                        <div class="col-xl-6 col-lg-10">
                                            <div class="block-gfx"><img class="w-100 rounded-3"
                                                src="/assets/images/overview/a.jpg" alt="" /></div>
                                        </div>
                                        <div class="col-xl-6">
                                            <div class="block-text pe-xl-7">
                                                <h3 class="mb-4">Generate months of social media content in minutes</h3>
                                                <p class="lead">Simply choose a template from available list to write content for blog
                                                    posts, landing page, website content etc.</p>
                                                <ul class="list gy-3">
                                                    <li><em class="icon text-primary ni ni-check-circle-fill"></em><span>Analyze your
                                                        business cost easily with group transaction thorugh tagging feature.</span>
                                                    </li>
                                                    <li><em class="icon text-primary ni ni-check-circle-fill"></em><span>Arrange your
                                                        business expenses by date, name, etc.</span></li>
                                                    <li><em class="icon text-primary ni ni-check-circle-fill"></em><span>Add more than
                                                        one card for payment. Integrated with more than 50+ payment method and
                                                        support bulk payment.</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane p-lg-3 fade" id="website-copy-seo" tabindex="0" role="tabpanel">
                                    <div class="row g-gs flex-xl-row-reverse">
                                        <div class="col-xl-6 col-lg-10">
                                            <div class="block-gfx"><img class="w-100 rounded-3"
                                                src="/assets/images/overview/b.jpg" alt="" /></div>
                                        </div>
                                        <div class="col-xl-6">
                                            <div class="block-text pe-xl-7">
                                                <h3 class="mb-4">Improve Your Website's Visibility and User Experience</h3>
                                                <p>Your website copy is one of the most important factors in determining whether or not
                                                    people stay on your website and take the actions you want them to take. It needs to
                                                    be clear, concise, and engaging, while also providing valuable information that
                                                    visitors will find useful.</p>
                                                <ul class="list gy-3">
                                                    <li><em class="icon text-primary ni ni-check-circle-fill"></em><span>Establish
                                                        trust: By providing helpful and informative content, you can establish
                                                        yourself as an authority in your field and build trust with your
                                                        audience.</span></li>
                                                    <li><em class="icon text-primary ni ni-check-circle-fill"></em><span>Build
                                                        credibility: When your website appears at the top of search results, it can
                                                        help to build credibility and trust with your audience.</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane p-lg-3 fade" id="blog-section-writing" tabindex="0" role="tabpanel">
                                    <div class="row g-gs flex-xl-row-reverse">
                                        <div class="col-xl-6 col-lg-10">
                                            <div class="block-gfx"><img class="w-100 rounded-3"
                                                src="/assets/images/overview/c.jpg" alt="" /></div>
                                        </div>
                                        <div class="col-xl-6">
                                            <div class="block-text pe-xl-7">
                                                <h3 class="mb-4">Engage Your Audience and Build Your Brand</h3>
                                                <p class="lead mb-5">In today's digital age, a blog is an essential tool for any
                                                    business looking to build its brand and engage with its audience.</p>
                                                <h4>1. Identify your target audience</h4>
                                                <p>Before you start writing, it's important to identify who your target audience is. Who
                                                    are you trying to reach with your blog? What are their interests and pain points?
                                                </p>
                                                <h4>2. Choose relevant topics</h4>
                                                <p>Your blog topics should be relevant to your business and your audience. Think about
                                                    the questions and concerns your customers</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane p-lg-3 fade" id="ecommerce-copy" tabindex="0" role="tabpanel">
                                    <div class="row g-gs flex-xl-row-reverse">
                                        <div class="col-xl-6 col-lg-10">
                                            <div class="block-gfx"><img class="w-100 rounded-3"
                                                src="/assets/images/overview/d.jpg" alt="" /></div>
                                        </div>
                                        <div class="col-xl-6">
                                            <div class="block-text pe-xl-7">
                                                <h3 class="mb-4">Introducing our new Wireless Bluetooth Earbuds!</h3>
                                                <p class="lead">Experience music like never before with our cutting-edge Wireless
                                                    Bluetooth Earbuds. With advanced noise-cancelling technology and crystal-clear
                                                    sound, you'll be able to fully immerse yourself in your favorite tunes.</p>
                                                <p>Our earbuds are ergonomically designed to fit comfortably in your ears, and they're
                                                    so lightweight that you'll hardly know you're wearing them. They're also sweat-proof
                                                    and water-resistant, so you can take them with you wherever you go, whether you're
                                                    running, hiking, or just lounging by the pool.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane p-lg-3 fade" id="magic-command" tabindex="0" role="tabpanel">
                                    <div class="row g-gs flex-xl-row-reverse">
                                        <div class="col-xl-6 col-lg-10">
                                            <div class="block-gfx">
                                                <img class="w-100 rounded-3"
                                                    src="/assets/images/overview/e.jpg" alt="" /></div>
                                        </div>
                                        <div class="col-xl-6">
                                            <div class="block-text pe-xl-7">
                                                <h3 class="mb-4">Quickly write candid testimonials and reviews for people and services
                                                </h3>
                                                <p class="lead">"I've been shopping with this company for years, and I can honestly say
                                                    that their customer service is second to none. I highly recommend this company to
                                                    anyone looking for a great shopping experience."</p>
                                                <p>Our earbuds are ergonomically designed to fit comfortably in your ears, and they're
                                                    so lightweight that you'll hardly know you're wearing them. They're also sweat-proof
                                                    and water-resistant, so you can take them with you wherever you go, whether you're
                                                    running, hiking, or just lounging by the pool.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section section-lg section-bottom-0 has-shape">
                    <div
                        className="nk-shape bg-shape-border-d mt-n8 mt-lg-n9 start-50 translate-middle-x"
                    ></div>
                    <div className="container">
                        <div className="section-head">
                            <div className="row justify-content-center text-center">
                                <div className="col-lg-9 col-xl-7">
                                    <h2 className="title">Content generation made easy</h2>
                                    <p className="lead">
                                        Give our AI a few descriptions and we'll automatically
                                        create blog articles, product descriptions and more for you
                                        within just few second.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="section-content">
                            <div className="row g-gs">
                                <div className="col-lg-4">
                                    <div className="card bg-gradient-light border-0 h-100">
                                        <div className="card-body p-3 d-flex">
                                            <div className="feature feature-inline align-items-center">
                                                <div className="feature-text me-auto">
                                                    <h4 className="title">Trustworthy Research</h4>
                                                    <p>
                                                        Simply choose a template from available list to
                                                        write content for blog posts, landing page, website
                                                        content etc.
                                                    </p>
                                                </div>
                                                <div
                                                    className="feature-decoration flex-shrink-0 ms-4 me-n3"
                                                >
                                                    <img
                                                        src="./assets/images/1-light.png"
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="card bg-gradient-light border-0 h-100">
                                        <div className="card-body p-3 d-flex">
                                            <div className="feature feature-inline align-items-center">
                                                <div className="feature-text me-auto">
                                                    <h4 className="title">Time Efficiency</h4>
                                                    <p>
                                                        Provide our AI content writer with few sentences on
                                                        what you want to write, and it will start writing
                                                        for you.
                                                    </p>
                                                </div>
                                                <div
                                                    className="feature-decoration flex-shrink-0 ms-4 me-n3"
                                                >
                                                    <img
                                                        src="./assets/images/2-light.png"
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="card bg-gradient-light border-0 h-100">
                                        <div className="card-body p-3 d-flex">
                                            <div className="feature feature-inline align-items-center">
                                                <div className="feature-text me-auto">
                                                    <h4 className="title">SEO Efficiency</h4>
                                                    <p>
                                                        Our powerful AI tools will generate content in few
                                                        second, then you can export it to wherever you need.
                                                    </p>
                                                </div>
                                                <div
                                                    className="feature-decoration flex-shrink-0 ms-4 me-n3"
                                                >
                                                    <img
                                                        src="./assets/images/3-light.png"
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section section-lg section-bottom-0">
                    <div className="container">
                        <div className="section-head">
                            <div className="row justify-content-center text-center">
                                <div className="col-lg-10 col-xl-9 col-xxl-8">
                                    <h2 className="title h1">
                                        How will Stralight unlock your creative potential?
                                    </h2>
                                    <p className="lead">
                                        Stralight allows you to create multiple pieces of content
                                        quickly and efficiently, increasing your productivity and
                                        freeing up time to focus on other important tasks.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="section-content">
                            <div className="row gy-gs gx-xxl-9">
                                <div className="col-md-6">
                                    <div className="feature feature-inline">
                                        <div className="feature-media">
                                            <div className="text-gradient-primary fs-2 d-inline-flex">
                                                <em className="icon ni ni-note-add"></em>
                                            </div>
                                        </div>
                                        <div className="feature-text">
                                            <h4 className="title">
                                                Write copy &amp; content that converts
                                            </h4>
                                            <p>
                                                You can generate high-quality content in seconds, saving
                                                you valuable time that you can spend on other important
                                                tasks.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="feature feature-inline">
                                        <div className="feature-media">
                                            <div className="text-gradient-primary fs-2 d-inline-flex">
                                                <em className="icon ni ni-bulb"></em>
                                            </div>
                                        </div>
                                        <div className="feature-text">
                                            <h4 className="title">Brainstorm new ideas.</h4>
                                            <p>
                                                An interview-style show featuring industry leaders,
                                                experts, and influencers, covering topics ranging from
                                                tech, politics, art, and more.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="feature feature-inline">
                                        <div className="feature-media">
                                            <div className="text-gradient-primary fs-2 d-inline-flex">
                                                <em className="icon ni ni-activity-round"></em>
                                            </div>
                                        </div>
                                        <div className="feature-text">
                                            <h4 className="title">10X your content output</h4>
                                            <p>
                                                Use templates to streamline your content creation
                                                process. This can include templates for blog posts,
                                                social media posts, videos, and more.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="feature feature-inline">
                                        <div className="feature-media">
                                            <div className="text-gradient-primary fs-2 d-inline-flex">
                                                <em className="icon ni ni-text-a"></em>
                                            </div>
                                        </div>
                                        <div className="feature-text">
                                            <h4 className="title">Write better, everywhere.</h4>
                                            <p>
                                                Reading widely can help improve your writing skills by
                                                exposing you to different writing styles, grammar
                                                structures, and vocabulary.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="feature feature-inline">
                                        <div className="feature-media">
                                            <div className="text-gradient-primary fs-2 d-inline-flex">
                                                <em className="icon ni ni-text2"></em>
                                            </div>
                                        </div>
                                        <div className="feature-text">
                                            <h4 className="title">Create content in 15 languages</h4>
                                            <p>
                                                Stralight can read and write content in 15 languages
                                                including English, Spanish, Japanese, and Portuguese.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="feature feature-inline">
                                        <div className="feature-media">
                                            <div className="text-gradient-primary fs-2 d-inline-flex">
                                                <em className="icon ni ni-puzzle"></em>
                                            </div>
                                        </div>
                                        <div className="feature-text">
                                            <h4 className="title">Build an AI-Powered business.</h4>
                                            <p>
                                                Building an AI-powered business is an ongoing process,
                                                and requires ongoing investment in data, technology, and
                                                expertise.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="feature feature-inline">
                                        <div className="feature-media">
                                            <div className="text-gradient-primary fs-2 d-inline-flex">
                                                <em className="icon ni ni-presentation"></em>
                                            </div>
                                        </div>
                                        <div className="feature-text">
                                            <h4 className="title">Collaborate with your team</h4>
                                            <p>
                                                Clear communication is key to successful collaboration.
                                                Be sure to express your ideas clearly and listen
                                                actively to others.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="feature feature-inline">
                                        <div className="feature-media">
                                            <div className="text-gradient-primary fs-2 d-inline-flex">
                                                <em className="icon ni ni-happy"></em>
                                            </div>
                                        </div>
                                        <div className="feature-text">
                                            <h4 className="title">Have fun! Stralight is a joy.</h4>
                                            <p>
                                                AI is an exciting, new technology that can unlock your
                                                imagination to create some amazing things.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <section className="section section-lg section-bottom-0 has-shape">
                    <div
                        className="nk-shape bg-shape-border-f ms-40p mt-8 start-50 translate-middle-x"
                    ></div>
                    <div className="container">
                        <div className="section-head">
                            <div className="row justify-content-center text-center">
                                <div className="col-lg-10 col-xl-9 col-xxl-8">
                                    <h2 className="title h1">See what AI writers have to say</h2>
                                    <p className="lead px-xxl-10">
                                        This is a straightforward and commonly used header that lets
                                        customers know they are looking at different pricing
                                        options.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="section-content">
                            <div className="row g-gs" data-masonry='{"percentPosition": true }'>
                                <div className="col-xl-4 col-md-6">
                                    <div
                                        className="card border-0 shadow-none rounded-4 bg-gradient-light"
                                    >
                                        <div className="card-body">
                                            <div className="d-flex pb-5">
                                                <div
                                                    className="media media-lg media-middle media-lg rounded-pill"
                                                >
                                                    <img
                                                        src="https://copygen.themenio.com/images/avatar/author/sm-e.jpg"
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="media-info ms-3">
                                                    <h5 className="mb-1">Ozioma Egwuonwu</h5>
                                                    <div className="sub-text">CEO at Hire &amp; Retain</div>
                                                </div>
                                            </div>
                                            <h4 className="title mb-3">Great app - Easy to use</h4>
                                            <p>
                                                Great value and so easy to use and saves me so much
                                                time! I was shocked by how much time and brain energy it
                                                saved me. Simple &amp; easy...gotta love that. ✌️
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-md-6">
                                    <div
                                        className="card border-0 shadow-none rounded-4 bg-gradient-light"
                                    >
                                        <div className="card-body">
                                            <div className="d-flex pb-5">
                                                <div
                                                    className="media media-lg media-middle media-lg rounded-pill"
                                                >
                                                    <img
                                                        src="https://copygen.themenio.com/images/avatar/author/sm-m.jpg"
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="media-info ms-3">
                                                    <h5 className="mb-1">Frances Burns</h5>
                                                    <div className="sub-text">CEO at Hire &amp; Retain</div>
                                                </div>
                                            </div>
                                            <h4 className="title mb-3">
                                                Like the name the Software also very SIMPLE to use
                                            </h4>
                                            <p>
                                                I was impressed I have to say, from the time it took me
                                                to come up with this stuff, your system improved my
                                                productivity. I think you have a creative tool, properly
                                                postured, it can generate alot of positive impact.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-md-6">
                                    <div
                                        className="card border-0 shadow-none rounded-4 bg-gradient-light"
                                    >
                                        <div className="card-body">
                                            <div className="d-flex pb-5">
                                                <div
                                                    className="media media-lg media-middle media-lg rounded-pill"
                                                >
                                                    <img
                                                        src="https://copygen.themenio.com/images/avatar/author/sm-b.jpg"
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="media-info ms-3">
                                                    <h5 className="mb-1">Ben Tortora</h5>
                                                    <div className="sub-text">CEO at Hire &amp; Retain</div>
                                                </div>
                                            </div>
                                            <h4 className="title mb-3">
                                                Just wanna shout out the whole team for this amazing
                                                tool
                                            </h4>
                                            <p>
                                                I love it! The simplicity of writing and designing in
                                                one app 🎉 Makes social media marketing a breeze ❤️
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-md-6">
                                    <div
                                        className="card border-0 shadow-none rounded-4 bg-gradient-light"
                                    >
                                        <div className="card-body">
                                            <div className="d-flex pb-5">
                                                <div
                                                    className="media media-lg media-middle media-lg rounded-pill"
                                                >
                                                    <img
                                                        src="https://copygen.themenio.com/images/avatar/author/sm-n.jpg"
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="media-info ms-3">
                                                    <h5 className="mb-1">Derek Gehl</h5>
                                                    <div className="sub-text">CEO at Hire &amp; Retain</div>
                                                </div>
                                            </div>
                                            <h4 className="title mb-3">
                                                Time Saving and Better Than PLR Content
                                            </h4>
                                            <p>
                                                CpoyGen is a fantastic tool for writing product
                                                descriptions and getting started on blog posts. No
                                                longer do I have to stare at a blank screen trying to
                                                figure out how to flesh out a topic beyond 50 words. For
                                                me, Stralight replaces buying PLR content that I would
                                                similarly edit and personalize. This saves me time
                                                because I can actually tailor to my keyword and niche
                                                needs.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-md-6">
                                    <div
                                        className="card border-0 shadow-none rounded-4 bg-gradient-light"
                                    >
                                        <div className="card-body">
                                            <div className="d-flex pb-5">
                                                <div
                                                    className="media media-lg media-middle media-lg rounded-pill"
                                                >
                                                    <img
                                                        src="https://copygen.themenio.com/images/avatar/author/sm-p.jpg"
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="media-info ms-3">
                                                    <h5 className="mb-1">Martin Schoel</h5>
                                                    <div className="sub-text">CEO at Hire &amp; Retain</div>
                                                </div>
                                            </div>
                                            <h4 className="title mb-3">
                                                They've hit a home run with this AI tool
                                            </h4>
                                            <p>
                                                I was literally speechless from the originality of the
                                                content that my very first Stralight test was able to
                                                produce from only a small sample of my original content.
                                                I was anticipating useless gibberish, but was instead
                                                genuinely dumbfounded. I stared at it in amazement for a
                                                solid 3 minutes while the idea machine in my head spun
                                                wildly, and I contemplated
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-md-6">
                                    <div
                                        className="card border-0 shadow-none rounded-4 bg-gradient-light"
                                    >
                                        <div className="card-body">
                                            <div className="d-flex pb-5">
                                                <div
                                                    className="media media-lg media-middle media-lg rounded-pill"
                                                >
                                                    <img
                                                        src="https://copygen.themenio.com/images/avatar/author/sm-o.jpg"
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="media-info ms-3">
                                                    <h5 className="mb-1">Merri Evans</h5>
                                                    <div className="sub-text">CEO at Hire &amp; Retain</div>
                                                </div>
                                            </div>
                                            <h4 className="title mb-3">
                                                A year of organic marketing in about 30 minutes
                                            </h4>
                                            <p>
                                                Writing articles has never been easier for me. Since I
                                                started using CopyGen, I only need a few minutes 🎉
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                <section className="section section-lg section-bottom-0">
                    <div className="container">
                        <div className="section-head">
                            <div className="row justify-content-center text-center">
                                <div className="col-lg-9 col-xl-7 col-xxl-8">
                                    <h2 className="title h1">
                                        Plans that best suit your business requirements
                                    </h2>
                                    <p className="lead px-lg-10 px-xxl-9">
                                        This is a straightforward and commonly used header that lets
                                        customers know they are looking at different pricing
                                        options.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="section-content">
                            <div className="row g-gs justify-content-center">
                                <div className="col-lg-4">
                                    <div className="pricing shadow-none">
                                        <div
                                            className="pricing-body p-5 bg-gradient-light d-md-flex d-lg-block h-100"
                                        >
                                            <div
                                                className="position-relative w-md-50 w-lg-100 pe-md-5 pe-lg-0 d-md-flex d-lg-block flex-column align-items-start"
                                            >
                                                <div
                                                    className="badge bg-primary rounded-pill position-absolute position-md-static position-lg-absolute top-0 end-0 mb-3 px-2"
                                                >
                                                    Most Popular
                                                </div>
                                                <div className="fs-6 mb-2">Pro</div>
                                                <h3 className="h2 mb-4 mt-auto">
                                                    $19
                                                    <span className="caption-text text-muted"> / month</span>
                                                </h3>
                                                <Link
                                                    to={PATH_AUTH.signUp}
                                                    className="btn btn-lg btn-block btn-primary rounded-1"
                                                >
                                                    <span>Start free trial today</span>
                                                    <em className="icon ni ni-arrow-long-right">
                                                    </em>
                                                </Link>
                                            </div>
                                            <ul
                                                className="list list-sap-border gx-0 gy-3 pt-5 pt-md-0 pt-lg-5 w-md-50 w-lg-100"
                                            >
                                                <li>
                                                    <em className="icon ni ni-check text-primary"></em>
                                                    <span
                                                    ><strong>50</strong> Articles per month</span
                                                    >
                                                </li>
                                                <li>
                                                    <em className="icon ni ni-check text-primary"></em>
                                                    <span><strong>10</strong> Templates</span>
                                                </li>
                                                <li>
                                                    <em className="icon ni ni-check text-primary"></em>
                                                    <span><strong>15</strong> Languages</span>
                                                </li>
                                                <li>
                                                    <em className="icon ni ni-check text-primary"></em>
                                                    <span>AI Article Writer</span>
                                                </li>
                                                <li>
                                                    <em className="icon ni ni-check text-primary"></em>
                                                    <span>Advance Editor Tool</span>
                                                </li>
                                                <li>
                                                    <em className="icon ni ni-check text-primary"></em>
                                                    <span>Regular support</span>
                                                </li>
                                                <li>
                                                    <em className="icon ni ni-check text-primary"></em>
                                                    <span>Priority support</span>
                                                </li>
                                                <li>
                                                    <em className="icon ni ni-cross text-primary"></em>
                                                    <span>Premium support</span>
                                                </li>
                                                <li>
                                                    <em className="icon ni ni-check text-primary"></em>
                                                    <span>Newest Features</span>
                                                </li>
                                                <li>
                                                    <em className="icon ni ni-check text-primary"></em>
                                                    <span>Cancel any time</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 order-lg-first">
                                    <div className="pricing shadow-none">
                                        <div className="pricing-body p-5 bg-gradient-light">
                                            <div className="position-relative">
                                                <div
                                                    className="badge bg-dark bg-opacity-30 border border-white border-opacity-10 rounded-pill position-absolute top-0 end-0 px-2"
                                                >
                                                    Active
                                                </div>
                                                <div className="fs-6 mb-2">Basic</div>
                                                <h3 className="h2 mb-4">Free</h3>
                                                <Link
                                                    to={PATH_AUTH.signUp}
                                                    className="btn btn-lg btn-block bg-dark bg-opacity-30 text-white hover-bg-primary rounded-1"
                                                >
                                                    <span>Start free trial today</span>
                                                    <em className="icon ni ni-arrow-long-right">
                                                    </em>
                                                </Link>
                                            </div>
                                            <ul
                                                className="list list-sap-border gx-0 gy-3 pt-5 pt-md-0 pt-lg-5 w-md-50 w-lg-100"
                                            >
                                                <li>
                                                    <em className="icon ni ni-check text-primary"></em>
                                                    <span
                                                    ><strong>10</strong> Articles per month</span
                                                    >
                                                </li>
                                                <li>
                                                    <em className="icon ni ni-check text-primary"></em>
                                                    <span><strong>1</strong> Templates</span>
                                                </li>
                                                <li>
                                                    <em className="icon ni ni-check text-primary"></em>
                                                    <span><strong>1</strong> Languages</span>
                                                </li>
                                                <li>
                                                    <em className="icon ni ni-check text-primary"></em>
                                                    <span>AI Article Writer</span>
                                                </li>
                                                <li>
                                                    <em className="icon ni ni-check text-primary"></em>
                                                    <span>Advance Editor Tool</span>
                                                </li>
                                                <li>
                                                    <em className="icon ni ni-check text-primary"></em>
                                                    <span>Regular support</span>
                                                </li>
                                                <li>
                                                    <em className="icon ni ni-cross text-primary"></em>
                                                    <span>Priority support</span>
                                                </li>
                                                <li>
                                                    <em className="icon ni ni-cross text-primary"></em>
                                                    <span>Premium support</span>
                                                </li>
                                                <li>
                                                    <em className="icon ni ni-check text-primary"></em>
                                                    <span>Newest Features</span>
                                                </li>
                                                <li>
                                                    <em className="icon ni ni-check text-primary"></em>
                                                    <span>Cancel any time</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="pricing shadow-none">
                                        <div className="pricing-body p-5 bg-gradient-light">
                                            <div className="position-relative">
                                                <div className="fs-6 mb-2">Diamond</div>
                                                <h3 className="h2 mb-4">
                                                    Customized Plan
                                                </h3>
                                                <Link
                                                    to={PATH_AUTH.signUp}
                                                    className="btn btn-lg btn-block bg-dark bg-opacity-30 text-white hover-bg-primary rounded-1"
                                                >
                                                    <span>Start free trial today</span>
                                                    <em className="icon ni ni-arrow-long-right">
                                                    </em>
                                                </Link>
                                            </div>
                                            <ul className="list list-sap-border gx-0 gy-3 pt-5">
                                                <li>
                                                    <em className="icon ni ni-check text-primary"></em>
                                                    <span>Custom pricing</span>
                                                </li>
                                                <li>
                                                    <em className="icon ni ni-check text-primary"></em>
                                                    <span>Custom number of users</span>
                                                </li>
                                                <li>
                                                    <em className="icon ni ni-check text-primary"></em>
                                                    <span>Custom number of articles</span>
                                                </li>
                                                <li>
                                                    <em className="icon ni ni-check text-primary"></em>
                                                    <span>Manage team member</span>
                                                </li>
                                                <li>
                                                    <em className="icon ni ni-check text-primary"></em>
                                                    <span>Premium support</span>
                                                </li>
                                                <li>
                                                    <em className="icon ni ni-check text-primary"></em>
                                                    <span>Newest Features</span>
                                                </li>
                                                <li>
                                                    <em className="icon ni ni-check text-primary"></em>
                                                    <span>Advance Editor Tool</span>
                                                </li>
                                                <li>
                                                    <em className="icon ni ni-check text-primary"></em>
                                                    <span>AI Article Writer</span>
                                                </li>
                                                <li>
                                                    <em className="icon ni ni-check text-primary"></em>
                                                    <span>Cancel any time</span>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section section-lg has-shape">
                    <div
                        className="nk-shape bg-shape-border-e ms-n25p mt-2 start-50 translate-middle-x"
                    ></div>
                    <div className="container">
                        <div
                            className="nk-shape bg-shape-wormhole-a mt-n45p mt-xl-n40p mt-xxl-n35p ms-2 start-50 top-100 translate-middle-x"
                        ></div>
                        <div className="section-head">
                            <div className="row justify-content-center text-center">
                                <div className="col-lg-9 col-xl-7 col-xxl-8">
                                    <h2 className="title h1">Questions &amp; Answers</h2>
                                    <p className="lead px-lg-10 px-xxl-9">
                                        This is a straightforward and commonly used header that lets
                                        customers know they are looking at different pricing
                                        options.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="section-content">
                            <div className="row g-gs justify-content-center">
                                <div className="col-xl-9 col-xxl-8">
                                    <div className="accordion accordion-separated" id="faq-1">
                                        <div className="accordion-item border-0 bg-gradient-light">
                                            <h2 className="accordion-header">
                                                <button
                                                    className="accordion-button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#faq-1-1"
                                                >
                                                    How to start using a Stralight
                                                </button>
                                            </h2>
                                            <div
                                                id="faq-1-1"
                                                className="accordion-collapse collapse show"
                                                data-bs-parent="#faq-1"
                                            >
                                                <div className="accordion-body">
                                                    Download the Stralight app, create an account, explore subjects, take quizzes, and learn from the explanations.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item border-0 bg-gradient-light">
                                            <h2 className="accordion-header">
                                                <button
                                                    className="accordion-button collapsed"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#faq-1-2"
                                                >
                                                    Does Stralight to write affiliate marketing content?
                                                </button>
                                            </h2>
                                            <div
                                                id="faq-1-2"
                                                className="accordion-collapse collapse"
                                                data-bs-parent="#faq-1"
                                            >
                                                <div className="accordion-body">
                                                    Stralight can definitely assist you with affiliate marketing content by providing ideas, outlines, and product information to craft compelling articles and reviews.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item border-0 bg-gradient-light">
                                            <h2 className="accordion-header">
                                                <button
                                                    className="accordion-button collapsed"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#faq-1-3"
                                                >
                                                    Is the generated content original?
                                                </button>
                                            </h2>
                                            <div
                                                id="faq-1-3"
                                                className="accordion-collapse collapse"
                                                data-bs-parent="#faq-1"
                                            >
                                                <div className="accordion-body">
                                                    Yes, you can write long articel for your blog posts,
                                                    product descriptions or any long article with Stralight.
                                                    We're always updating our template and tools, so let
                                                    us know what are expecting!
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item border-0 bg-gradient-light">
                                            <h2 className="accordion-header">
                                                <button
                                                    className="accordion-button collapsed"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#faq-1-4"
                                                >
                                                    Do you have wordpress plugin?
                                                </button>
                                            </h2>
                                            <div
                                                id="faq-1-4"
                                                className="accordion-collapse collapse"
                                                data-bs-parent="#faq-1"
                                            >
                                                <div className="accordion-body">
                                                    Yes, Stralight actually offers a WordPress plugin alongside a Chrome extension to streamline your affiliate content creation process.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
}

export default Home;
