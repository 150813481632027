
import React from 'react';
import { Link } from "react-router-dom";

function Logo() {

    return (
        <>
            <div className="nk-header-logo">
                <Link
                    to="/"
                    className="logo-link"
                >
                    <div className="logo-wrap">
                        <img
                            className="logo-img logo-light"
                            src="/assets/images/logo.png"
                            alt=""
                        />
                        <img
                            className="logo-img logo-dark"
                            src="/assets/images/logo-dark.png"
                            alt="" />
                    </div>
                </Link>
            </div>
            <div className="nk-header-toggle">
                <button className="dark-mode-toggle dark-active">
                    <em className="on icon ni ni-moon-fill"></em>
                    <em className="off icon ni ni-sun-fill"></em>
                </button>
                <button className="btn btn-light btn-icon header-menu-toggle">
                    <em className="icon ni ni-menu"></em>
                </button>
            </div>
        </>
    )
}

export default Logo;