
import React from 'react';

import Logo from '../components/Logo';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import EndWriterBlockToday from '../components/EndWriterBlockToday';

function Features() {

    return (
        <>
            <header className="nk-header">
                <div
                    className="nk-header-main nk-menu-main will-shrink is-transparent ignore-mask"
                >
                    <div className="container">
                        <div className="nk-header-wrap">
                            <Logo />
                            <Menu />
                        </div>
                    </div>
                </div>
            </header>
            <main className="nk-pages">
                <section className="section section-bottom-0 has-shape has-mask">
                    <div
                        className="nk-shape bg-shape-blur-m mt-8 start-50 top-0 translate-middle-x"
                    ></div>
                    <div
                        className="nk-mask bg-pattern-dot bg-blend-around mt-n5 mb-10p mh-50vh"
                    ></div>
                    <div className="container">
                        <div className="section-head">
                            <div className="row justify-content-center text-center">
                                <div className="col-lg-8 col-xl-7 col-xxl-6">
                                    <h6 className="overline-title text-primary">Feature</h6>
                                    <h2 className="title h1">
                                        Generate AI-powered content in 1 click.
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <div className="section-content">
                            <div className="row g-gs">
                                <div className="col-md-6 col-xl-4">
                                    <div className="card rounded-4 border-0 shadow-tiny h-100">
                                        <div className="card-body">
                                            <div className="feature">
                                                <div className="feature-media">
                                                    <div
                                                        className="media media-middle media-xl media-border text-bg-info-soft-outline rounded-pill"
                                                    >
                                                        <em className="icon ni ni-spark"></em>
                                                    </div>
                                                </div>
                                                <div className="feature-text">
                                                    <h3 className="title">Write content faster</h3>
                                                    <p>
                                                        You do not need to spend hours to write good content
                                                        — let our advance AI Writer to get it done.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-4">
                                    <div className="card rounded-4 border-0 shadow-tiny h-100">
                                        <div className="card-body">
                                            <div className="feature">
                                                <div className="feature-media">
                                                    <div
                                                        className="media media-middle media-xl media-border text-bg-warning-soft-outline rounded-pill"
                                                    >
                                                        <em className="icon ni ni-cpu"></em>
                                                    </div>
                                                </div>
                                                <div className="feature-text">
                                                    <h3 className="title">Tools and templates</h3>
                                                    <p>
                                                        Using our AI tools and pre-built template to create
                                                        content briefs, write and optimize content in one
                                                        place.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-4">
                                    <div className="card rounded-4 border-0 shadow-tiny h-100">
                                        <div className="card-body">
                                            <div className="feature">
                                                <div className="feature-media">
                                                    <div
                                                        className="media media-middle media-xl media-border text-bg-primary-soft-outline rounded-pill"
                                                    >
                                                        <em className="icon ni ni-bulb"></em>
                                                    </div>
                                                </div>
                                                <div className="feature-text">
                                                    <h3 className="title">Brainstorm faster</h3>
                                                    <p>
                                                        Use our advanced AI as your personal content writer
                                                        or partner for your endless work for your business.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-4">
                                    <div className="card rounded-4 border-0 shadow-tiny h-100">
                                        <div className="card-body">
                                            <div className="feature">
                                                <div className="feature-media">
                                                    <div
                                                        className="media media-middle media-xl media-border text-bg-success-soft-outline rounded-pill"
                                                    >
                                                        <em className="icon ni ni-wallet"></em>
                                                    </div>
                                                </div>
                                                <div className="feature-text">
                                                    <h3 className="title">Affiliate monetization</h3>
                                                    <p>
                                                        Power up your content with embedded affiliate links and automated affiliate tag integration. Unleash your earning potential with effortless commission generation.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-4">
                                    <div className="card rounded-4 border-0 shadow-tiny h-100">
                                        <div className="card-body">
                                            <div className="feature">
                                                <div className="feature-media">
                                                    <div
                                                        className="media media-middle media-xl media-border text-bg-purple-soft-outline rounded-pill"
                                                    >
                                                        <em className="icon ni ni-file-docs"></em>
                                                    </div>
                                                </div>
                                                <div className="feature-text">
                                                    <h3 className="title">Copy and publish anywhere</h3>
                                                    <p>
                                                        You can simply copy your desire content and then you
                                                        can publish, like Shopify, WordPress, or anywhere.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-4">
                                    <div className="card rounded-4 border-0 shadow-tiny h-100">
                                        <div className="card-body">
                                            <div className="feature">
                                                <div className="feature-media">
                                                    <div
                                                        className="media media-middle media-xl media-border text-bg-info-soft-outline rounded-pill"
                                                    >
                                                        <em className="icon ni ni-wordpress"></em>
                                                    </div>
                                                </div>
                                                <div className="feature-text">
                                                    <h3 className="title"> WordPress Content plugin </h3>
                                                    <p>
                                                        Effortlessly craft captivating articles enriched with stunning Amazon images in a matter of seconds. Experience the power of seamless content creation at your fingertips.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-4">
                                    <div className="card rounded-4 border-0 shadow-tiny h-100">
                                        <div className="card-body">
                                            <div className="feature">
                                                <div className="feature-media">
                                                    <div
                                                        className="media media-middle media-xl media-border text-bg-warning-soft-outline rounded-pill"
                                                    >
                                                        <em className="icon ni ni-swap-alt"></em>
                                                    </div>
                                                </div>
                                                <div className="feature-text">
                                                    <h3 className="title">Repurpose content easily</h3>
                                                    <p>
                                                        Write and saved once, use everywhere. Also rewrite
                                                        content for different porpose with minimal effort.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-4">
                                    <div className="card rounded-4 border-0 shadow-tiny h-100">
                                        <div className="card-body">
                                            <div className="feature">
                                                <div className="feature-media">
                                                    <div
                                                        className="media media-middle media-xl media-border text-bg-primary-soft-outline rounded-pill"
                                                    >
                                                        <em className="icon ni ni-edit"></em>
                                                    </div>
                                                </div>
                                                <div className="feature-text">
                                                    <h3 className="title">Blog Post & Articles</h3>
                                                    <p>
                                                        Generate optimized blog post and articles to get organic traffic - making you visible to the world.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-4">
                                    <div className="card rounded-4 border-0 shadow-tiny h-100">
                                        <div className="card-body">
                                            <div className="feature">
                                                <div className="feature-media">
                                                    <div
                                                        className="media media-middle media-xl media-border text-bg-danger-soft-outline rounded-pill"
                                                    >
                                                        <div className="d-flex align-items-end">
                                                            <em className="icon ni ni-text"></em
                                                            ><em className="icon half ms-n2 ni ni-text"></em>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="feature-text">
                                                    <h3 className="title">Write in Any Language</h3>
                                                    <p>
                                                        Let AI write for you in over 40 languages. Our AI
                                                        can write in English, Spanish, French and many more
                                                        language.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section section-bottom-0">
                    <div className="container">
                        <div className="section-head">
                            <div className="row justify-content-center text-center">
                                <div className="col-lg-8 col-xl-7 col-xxl-6">
                                    <h6 className="overline-title text-primary">How it works</h6>
                                    <h2 className="title">Few steps to write content</h2>
                                </div>
                            </div>
                        </div>
                        <div className="section-content">
                            <div
                                className="row g-gs justify-content-center flex-md-row-reverse align-items-center"
                            >
                                <div className="col-xl-4 col-lg-5 col-md-6">
                                    <div className="rounded-4 bg-info bg-opacity-50 p-5 pe-0">
                                        <div className="block-gfx me-n4">
                                            <img
                                                className="w-100 rounded-3 shadow-sm"
                                                src="./assets/images/process/a.jpg"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-5 col-md-6">
                                    <div className="block-text">
                                        <div
                                            className="media media-middle text-bg-primary-soft rounded-pill fw-medium fs-5 mb-3"
                                        >
                                            01
                                        </div>
                                        <h3 className="title">Select a Template</h3>
                                        <p>
                                            Sed ut perspiciatis unde omnis iste natus error sit
                                            voluptatem accusantium doloremque laudantium, totam rem
                                            aperiam, quasi architecto beatae vitae dicta sunt
                                            explicabo.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="sction-sap text-center py-3 d-none d-md-block">
                            <svg
                                className="h-3rem h-sm-5rem h-lg-7rem"
                                viewBox="0 0 444 112"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M442.989 1C443.49 18.4197 426.571 53.2592 354.892 53.2591C265.293 53.2591 126.139 53.2591 80.0875 53.2591C34.0366 53.2591 7.00663 85.784 0.999979 111"
                                    stroke="currentColor"
                                    stroke-dasharray="7 7"
                                />
                            </svg>
                        </div>
                        <div className="h-3rem d-md-none"></div>
                        <div className="section-content">
                            <div className="row g-gs justify-content-center align-items-center">
                                <div className="col-xl-4 col-lg-5 col-md-6">
                                    <div className="rounded-4 bg-primary bg-opacity-40 p-5 ps-0">
                                        <div className="block-gfx ms-n4">
                                            <img
                                                className="w-100 rounded-3 shadow-sm"
                                                src="./assets/images/process/b.jpg"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-5 col-md-6">
                                    <div className="block-text px-xxl-5">
                                        <div
                                            className="media media-middle text-bg-primary-soft rounded-pill fw-medium fs-5 mb-3"
                                        >
                                            02
                                        </div>
                                        <h3 className="title">Fill in Your Product Details</h3>
                                        <p>
                                            Explain with as many details as possible to the AI what
                                            you would like to write about.
                                        </p>
                                        <ul className="list gy-2">
                                            <li>
                                                <em className="icon ni ni-dot"></em
                                                ><span>At vero eos et accusamus et iusto</span>
                                            </li>
                                            <li>
                                                <em className="icon ni ni-dot"></em
                                                ><span>At vero eos et accusamus et iusto</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="sction-sap text-center py-3 d-none d-md-block">
                            <svg
                                className="h-3rem h-sm-5rem h-lg-7rem"
                                viewBox="0 0 444 114"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M1.01068 1C0.510125 18.7364 17.4289 54.2093 89.1082 54.2093C178.707 54.2093 317.861 54.2093 363.912 54.2093C409.963 54.2093 436.993 87.3256 443 113"
                                    stroke="currentColor"
                                    stroke-dasharray="7 7"
                                />
                            </svg>
                        </div>
                        <div className="h-3rem d-md-none"></div>
                        <div className="section-content">
                            <div
                                className="row g-gs justify-content-center flex-md-row-reverse align-items-center"
                            >
                                <div className="col-xl-4 col-lg-5 col-md-6">
                                    <div className="rounded-4 bg-pink bg-opacity-30 p-5 pe-0">
                                        <div className="block-gfx me-n4">
                                            <img
                                                className="w-100 rounded-3 shadow-sm"
                                                src="./assets/images/process/c.jpg"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-5 col-md-6">
                                    <div className="block-text">
                                        <div
                                            className="media media-middle text-bg-primary-soft rounded-pill fw-medium fs-5 mb-3"
                                        >
                                            03
                                        </div>
                                        <h3 className="title">Generate AI Content</h3>
                                        <p>
                                            Our highly trained AI understands your details and
                                            generate unique and human-like content in seconds.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <EndWriterBlockToday />
            </main>
            <Footer />
        </>
    );
}

export default Features;
