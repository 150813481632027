
import React, { useState } from 'react';
import axios from 'axios';
import { CgLoadbarAlt } from 'react-icons/cg';

import capitalizeFirstLetter from '../utils/utils';
import { HOST_API } from '../config';

import Logo from '../components/Logo';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';


function Contact() {
    const [submitBtn, setSubmitBtn] = useState('Send Message');
    const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitBtn(<CgLoadbarAlt />)
        setSubmitBtnDisabled(true)

        try {
            console.log(formData)
            axios.post(HOST_API + "contact/new", formData)
                .then(res => {
                    console.log(res);
                    const resObj = res.data
                    if (resObj.code === 200) {
                        // console.log(resObj.data.token)
                        // localStorage.setItem("user", resObj.data.token)
                        setSuccess(resObj.msg)

                        setSubmitBtn('Send Message')
                        setSubmitBtnDisabled(false)

                        // Reset the form
                        setFormData({
                            name: '',
                            email: '',
                            subject: '',
                            message: ''
                        });
                        // navigate(PATH_AUTH.signIn, { replace: true })
                    } else {
                        setSubmitBtn('Send Message')
                        setSubmitBtnDisabled(false)
                        setError(resObj.msg)
                    }

                }).catch(err => {
                    setError("Network Error")
                    console.log(err)
                    console.log('Network Error')
                    setSubmitBtn('Send Message')
                    setSubmitBtnDisabled(false)
                })

        } catch (error) {
            // Handle any errors that may occur during the request
            console.error('Error:', error);
            setSubmitBtn('Send Message')
            setSubmitBtnDisabled(false)
        }
    };

    return (
        <>
            <header className="nk-header">
                <div
                    className="nk-header-main nk-menu-main will-shrink is-transparent ignore-mask"
                >
                    <div className="container">
                        <div className="nk-header-wrap">
                            <Logo />
                            <Menu />
                        </div>
                    </div>
                </div>
            </header>
            <main className="nk-pages">
                <section className="section section-bottom-0 has-shape has-mask">
                    <div
                        className="nk-shape bg-shape-blur-m mt-8 start-50 top-0 translate-middle-x"
                    ></div>
                    <div
                        className="nk-mask bg-pattern-dot bg-blend-around mt-n5 mb-10p mh-50vh"
                    ></div>
                    <div className="container">
                        <div className="section-head">
                            <div className="row justify-content-center text-center">
                                <div className="col-lg-11 col-xl-10 col-xxl-9">
                                    <h6 className="overline-title text-primary">Contact us</h6>
                                    <h1 className="title">
                                        We love getting feedback, Questions, and hearing what you have to
                                        say!
                                    </h1>
                                </div>
                            </div>
                        </div>
                        <div className="section-content">
                            <div className="row g-gs justify-content-center justify-content-lg-between">
                                <div className="col-xl-5 col-lg-6 col-md-8 text-lg-start text-center">
                                    <div className="block-text pt-lg-4">
                                        <h3 className="title h2">Let's talk</h3>
                                        <p>
                                            Must explain to you how all this mistaken idea of denouncing
                                            pleasure and praising born and I will give you a complete
                                            account of the system.
                                        </p>
                                        <ul className="row gy-4 pt-4">
                                            {/* <li className="col-12">
                                                <h5>Contact</h5>
                                                <div
                                                    className="d-flex flex-column flex-lg-row align-items-center justify-content-center justify-content-lg-start"
                                                >
                                                    <em
                                                        className="icon text-base fs-5 mb-2 mb-lg-0 me-lg-2 ni ni-call-alt-fill"
                                                    ></em
                                                    ><span>+(642) 342 762 44</span>
                                                </div>
                                            </li> */}
                                            <li className="col-12">
                                                <h5>Email</h5>
                                                <div
                                                    className="d-flex flex-column flex-lg-row align-items-center justify-content-center justify-content-lg-start"
                                                >
                                                    <em
                                                        className="icon text-base fs-5 mb-2 mb-lg-0 me-lg-2 ni ni-mail-fill"
                                                    ></em
                                                    ><span>support@stralight.com</span>
                                                </div>
                                            </li>
                                            <li className="col-12">
                                                <h5>Office</h5>
                                                <div
                                                    className="d-flex flex-column flex-lg-row align-items-center justify-content-center justify-content-lg-start"
                                                >
                                                    <em className="icon text-base fs-5 mb-2 mb-lg-0 me-lg-2 ni ni-map-pin-fill"></em>
                                                    <span>447 Broadway, 2nd Floor, New York, United State.
                                                    </span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="card border-0 shadow-sm rounded-4">
                                        <div className="card-body">
                                            <h3 className="title fw-medium mb-4">
                                                Please feel free to contact us using form below
                                            </h3>
                                            <form
                                                className="form-submit-init"
                                                noValidate onSubmit={handleSubmit}
                                            >
                                                <div className="row g-4">
                                                    {error && <div className="alert alert-danger" role="alert"><p>{capitalizeFirstLetter(error)}</p></div>}
                                                    {success && <div className="alert alert-success" role="alert"><p>{capitalizeFirstLetter(success)}</p></div>}
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <div className="form-control-wrap">
                                                                <input
                                                                    type="text"
                                                                    name="name"
                                                                    id="name"
                                                                    className="form-control form-control-lg"
                                                                    placeholder="Your Name"
                                                                    value={formData.name}
                                                                    onChange={handleChange}
                                                                    required=""
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <div className="form-control-wrap">
                                                                <input
                                                                    type="email"
                                                                    name="email"
                                                                    id="email"
                                                                    className="form-control form-control-lg"
                                                                    placeholder="Your Email Address"
                                                                    value={formData.email}
                                                                    onChange={handleChange}
                                                                    required=""
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <div className="form-control-wrap">
                                                                <input
                                                                    type="text"
                                                                    name="subject"
                                                                    id="subject"
                                                                    className="form-control form-control-lg"
                                                                    placeholder="Subject"
                                                                    value={formData.subject}
                                                                    onChange={handleChange}
                                                                    required=""
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <div className="form-control-wrap">
                                                                <textarea
                                                                    name="message"
                                                                    id="message"
                                                                    className="form-control form-control-lg"
                                                                    placeholder="Enter your message"
                                                                    value={formData.message}
                                                                    onChange={handleChange}
                                                                    required=""
                                                                ></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <button
                                                                className="btn btn-primary"
                                                                type="submit"
                                                                id="submit-btn"
                                                                disabled={submitBtnDisabled}
                                                            >
                                                                {submitBtn}
                                                            </button>
                                                        </div>
                                                        <div className="form-result mt-4"></div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section section-bottom-0">
                    <div className="container">
                        <div className="section-content">
                            <div className="row g-gs justify-content-center">
                                <div className="col-md-6 col-xl-4">
                                    <div className="card h-100 rounded-4 border-0 shadow-sm">
                                        <div className="card-body">
                                            <div className="feature">
                                                <div className="feature-text">
                                                    <h3 className="title">Get 1:1 Demo</h3>
                                                    <p>
                                                        Finding it difficult to navigate our suite of products?
                                                        Get on a call with our product experts for personalized
                                                        demo
                                                    </p>
                                                    {/* <a className="link link-primary" href="#"
                                                    ><span className="fw-bold">Schedule a call</span
                                                    ><em className="icon fs-5 ni ni-arrow-long-right"></em
                                                    ></a> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-4">
                                    <div className="card h-100 rounded-4 border-0 shadow-sm">
                                        <div className="card-body">
                                            <div className="feature">
                                                <div className="feature-text">
                                                    <h3 className="title">24X7 Chat Support</h3>
                                                    <p>
                                                        Get on a call with our excellent customer support team by
                                                        using our 24X7 live chat support. We are here to help!
                                                    </p>
                                                    {/* <a className="link link-primary" href="#"
                                                    ><span className="fw-bold">Talk to Support</span
                                                    ><em className="icon fs-5 ni ni-arrow-long-right"></em
                                                    ></a> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-4">
                                    <div className="card h-100 rounded-4 border-0 shadow-sm">
                                        <div className="card-body">
                                            <div className="feature">
                                                <div className="feature-text">
                                                    <h3 className="title">Request Feature</h3>
                                                    <p>
                                                        Have an out of the box idea for a new AI feature to add.
                                                        We are all ears.
                                                    </p>
                                                    {/* <a className="link link-primary" href="#"
                                                    ><span className="fw-bold">Request a feature</span
                                                    ><em className="icon fs-5 ni ni-arrow-long-right"></em
                                                    ></a> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section section-bottom-0">
                    <div className="container">
                        <div className="section-wrap bg-primary bg-opacity-10 rounded-4">
                            <div className="section-content bg-pattern-dot-sm p-4 p-sm-6">
                                <div className="row justify-content-center text-center">
                                    <div className="col-xl-8 col-xxl-9">
                                        <div className="block-text">
                                            <h6 className="overline-title text-primary">
                                                Boost your writing productivity
                                            </h6>
                                            <h2 className="title">End writer’s block today</h2>
                                            <p className="lead mt-3">
                                                It’s like having access to a team of copywriting experts
                                                writing powerful copy for you in 1-click.
                                            </p>
                                            <ul className="btn-list btn-list-inline">
                                                <li>
                                                    <Link to="#" className="btn btn-lg btn-primary"
                                                    ><span>Start writing for free</span
                                                    ><em className="icon ni ni-arrow-long-right"></em
                                                    ></Link>
                                                </li>
                                            </ul>
                                            <ul className="list list-row gy-0 gx-3">
                                                <li>
                                                    <em className="icon ni ni-check-circle-fill text-success"></em
                                                    ><span>No credit card required</span>
                                                </li>
                                                <li>
                                                    <em className="icon ni ni-check-circle-fill text-success"></em
                                                    ><span>Cancel anytime</span>
                                                </li>
                                                <li>
                                                    <em className="icon ni ni-check-circle-fill text-success"></em
                                                    ><span>10+ tools to expolore</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <Footer />
        </>
    );
}

export default Contact;
