import { Link } from "react-router-dom";
import { PATH_AUTH, PATH_PAGE } from "../routes/paths";

export default function Footer() {

    return (
        <footer className="nk-footer pt-4 pt-lg-7">
            <div className="section section-top-0">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-lg-3 col-md-9 me-auto">
                            <div className="block-text">
                                <Link
                                    to="/"
                                    className="logo-link mb-4"
                                >
                                    <div className="logo-wrap">
                                        <img
                                            className="logo-img logo-light"
                                            src="/assets/images/logo.png"
                                            alt=""
                                        />
                                        <img
                                            className="logo-img logo-dark"
                                            src="/assets/images/logo-dark.png"
                                            alt=""
                                        />
                                    </div>
                                </Link>
                                <p className="fs-4 ">
                                    Generate AI-powered content in clicks.
                                </p>
                                <ul className="btn-list btn-list-inline g-0">
                                    <li>
                                        {/* <Link to="/" className="btn btn-lg btn-primary">
                                            Become an Affiliate
                                        </Link> */}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-sm-4 col-6">
                            <div className="wgs">
                                <h6 className="wgs-title overline-title text-heading mb-3">
                                    Use Case
                                </h6>
                                <ul className="list gy-2 list-link-base">
                                    <li><Link className="link-base" to="#">AI Writer</Link></li>
                                    <li><Link className="link-base" to="#">AI Articel Writer</Link></li>
                                    <li><Link className="link-base" to="#">Content Generator</Link></li>
                                    <li><Link className="link-base" to="#">Content Rewriter</Link></li>
                                    <li><Link className="link-base" to="#">Blog Post Writer</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-sm-4 col-6">
                            <div className="wgs">
                                <h6 className="wgs-title overline-title text-heading mb-3">
                                    About
                                </h6>
                                <ul className="list gy-2 list-link-base">
                                    <li><Link className="link-base" to={PATH_PAGE.pricing}>Pricing</Link></li>
                                    <li><Link className="link-base" to={PATH_AUTH.signIn}>Login</Link></li>
                                    <li><Link className="link-base" to={PATH_AUTH.signUp}>Sign up</Link></li>
                                    <li><Link className="link-base" to="#">Privacy Policy</Link></li>
                                    <li>
                                        <Link className="link-base" to={PATH_PAGE.termsCondition}>Terms &amp; Conditions</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-4">
                            <div className="wgs">
                                <h6 className="wgs-title overline-title text-heading mb-3">
                                    Contacts
                                </h6>
                                <p>
                                    If you need help using our service, or have a question about
                                    it, please feel free to contact us.
                                </p>
                                <Link to="/" className="link link-primary">support@stralight.com</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <hr className="border-opacity-25 border-white m-0" />
                <div className="py-5">
                    <div className="row">
                        <div className="col-md">
                            <p className="mb-2 mb-md-0">
                                &copy; {new Date().getFullYear()} Stralight. Powered by
                                <Link href="https://strano.com.ng" className="fw-bold">&nbsp;Strano Labs</Link>.
                            </p>
                        </div>
                        <div className="col-md">
                            <ul
                                className="btn-list btn-list-inline g-1 justify-content-start justify-content-md-end"
                            >
                                <li>
                                    <Link className="link-base" href="#"
                                    ><em className="icon fs-4 ni ni-facebook-fill"></em
                                    ></Link>
                                </li>
                                <li>
                                    <Link className="link-base" href="#"
                                    ><em className="icon fs-4 ni ni-twitter-round"></em
                                    ></Link>
                                </li>
                                <li>
                                    <Link className="link-base" href="#"
                                    ><em className="icon fs-4 ni ni-youtube-round"></em
                                    ></Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}
