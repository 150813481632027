
import React from 'react';
import { Link } from "react-router-dom";
import { PATH_AUTH } from '../routes/paths';

function EndWriterBlockToday() {

    return (
        <section className="section section-bottom-0">
            <div className="container">
                <div className="section-wrap bg-primary bg-opacity-10 rounded-4">
                    <div className="section-content bg-pattern-dot-sm p-4 p-sm-6">
                        <div className="row justify-content-center text-center">
                            <div className="col-xl-8 col-xxl-9">
                                <div className="block-text">
                                    <h6 className="overline-title text-primary">
                                        Boost your writing productivity
                                    </h6>
                                    <h2 className="title">End writer’s block today</h2>
                                    <p className="lead mt-3">
                                        It’s like having access to a team of copywriting experts
                                        writing powerful copy for you in 1-click.
                                    </p>
                                    <ul className="btn-list btn-list-inline">
                                        <li>
                                            <Link to={PATH_AUTH.signUp} className="btn btn-lg btn-primary"
                                            >
                                                <span>Start writing for free</span>
                                                <em className="icon ni ni-arrow-long-right">
                                                </em>
                                            </Link>
                                        </li>
                                    </ul>
                                    <ul className="list list-row gy-0 gx-3">
                                        <li>
                                            <em
                                                className="icon ni ni-check-circle-fill text-success"
                                            ></em
                                            ><span>No credit card required</span>
                                        </li>
                                        <li>
                                            <em
                                                className="icon ni ni-check-circle-fill text-success"
                                            ></em
                                            ><span>Cancel anytime</span>
                                        </li>
                                        <li>
                                            <em
                                                className="icon ni ni-check-circle-fill text-success"
                                            ></em
                                            ><span>10+ tools to expolore</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default EndWriterBlockToday;
